<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="content">
        <!-- 标题 -->
        <div class="title_box">
          <!-- <span>{{ this.$route.params.chapter_name }}</span
          ><span>&nbsp;>&nbsp;</span> -->
          <span @click="back">{{ this.$route.params.router_name }}</span
          ><span>&nbsp;>&nbsp;</span
          ><span>{{ this.$route.params.paper_name }}</span>
        </div>
        <div class="introduce_box">
          <!-- 二维码 -->
          <div class="code_box">
            <span>手机做题</span>
            {{ paper.code }}
            <div>
              扫码下载
              <p>APP</p>
              &nbsp;&nbsp;&nbsp;随时随地刷题
            </div>
            <div class="line"></div>
            <div class="code_img">
              <img :src="code" alt="" />
            </div>
          </div>
          <div class="introduce_left">
            <!-- 试卷名字 -->
            <div class="paper_name">{{ this.$route.params.paper_name }}</div>
            <!-- 试卷介绍 -->
            <div class="paper_info">
              <!-- 无权限状态 -->
              <div class="no_access" v-if="code_status.code == 0">
                <div class="no_enroll" v-if="show_ipt == false">
                  <img src="../../../../assets/img/wukemu.png" alt="" />
                  <div>{{ code_status.msg }}</div>
                  <button type="button" @click="show_ipt = true">
                    立即报名
                  </button>
                </div>
                <!-- 联系客服 -->
                <div class="contact_box" v-if="show_ipt == true">
                  <p>联系客服</p>
                  <p>
                    请选择您打算报考的专业和电话，后续会有客服与您联系确定报班事宜
                  </p>
                  <div class="ipt_box">
                    <div>
                      <div>*</div>
                      报考专业
                    </div>
                    <el-select
                      v-model="formInline.subject_id"
                      placeholder="请选择您的报考专业"
                    >
                      <el-option
                        v-for="item in subjectList"
                        :key="item.subject_id"
                        :label="item.name"
                        :value="item.subject_id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="ipt_box" style="margin-top: 30px">
                    <div>
                      <div>*</div>
                      手机号
                    </div>
                    <el-input
                      placeholder="请输入您的手机号"
                      v-model="formInline.mobile"
                      maxlength="11"
                      style="margin-top: 12px"
                    ></el-input>
                  </div>
                  <button type="button" @click="onSubmit">点击提交</button>
                </div>
              </div>

              <div class="info_content" v-if="code_status.code == 1">
                <!-- 顶部 -->
                <div class="info_top">
                  <ul>
                    <li>
                      <img src="../../../../assets/img/leixing.png" alt="" />
                      <div>
                        <p>试题类型：</p>
                        <p>真题试卷</p>
                      </div>
                    </li>
                    <li>
                      <img src="../../../../assets/img/xiangmu.png" alt="" />
                      <div>
                        <p>所属项目：</p>
                        <p>{{ paper.project_title }}</p>
                      </div>
                    </li>
                    <li>
                      <img src="../../../../assets/img/kemu (2).png" alt="" />
                      <div>
                        <p>所属科目：</p>
                        <p>{{ paper.subject_title }}</p>
                      </div>
                    </li>
                    <li>
                      <img src="../../../../assets/img/nandu.png" alt="" />
                      <div>
                        <p>试题难度：</p>
                        <p>适中</p>
                      </div>
                    </li>
                    <li>
                      <img src="../../../../assets/img/defen.png" alt="" />
                      <div>
                        <p>试卷总分</p>
                        <p>{{ paper.total_score }}</p>
                        <!-- <p>中药学综合知识与技能</p> -->
                      </div>
                    </li>
                    <li>
                      <img src="../../../../assets/img/time.png" alt="" />
                      <div>
                        <p>答题时间</p>
                        <p>{{ paper.time_length / 60 }}分钟</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- 开始做题 -->
                <div class="info_down">
                  <div>
                    <img src="../../../../assets/img/1.png" alt="" />
                    <img src="../../../../assets/img/2 (2).png" alt="" />
                    <img src="../../../../assets/img/3 (2).png" alt="" />
                    <img src="../../../../assets/img/4.png" alt="" />
                    <p>{{ paper.people_nums }}人做过</p>
                  </div>
                  <button
                    type="button"
                    @click="startTest"
                    v-if="paper.is_finish == 1"
                  >
                    开始考试
                  </button>
                  <button type="button" @click="continueTest" v-else>
                    继续考试
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      paper: {}, // 试卷详情
      code_status: {}, // code状态
      show_ipt: false, // 是否显示联系客服
      code: "", // 二维码
      test_paper: "", // 试卷地址
      subjectList: [], // 专业列表
      formInline: {
        mobile: "", // 用户手机号
        subject_id: "", // 专业id
      },
      show_ipt: false, // 是否显示联系客服
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    this.getPaper();
    this.getCode();
    this.getSubject();
  },
  mounted() {},
  watch: {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 获取试卷
    getPaper() {
      let data = {
        chapter_category: this.$route.params.chapter_id,
        paper_id: this.$route.params.paper_id, // 试卷id
        //
      };
      api.test_papers(data).then((res) => {
        console.log(res)
        this.code_status = res.data;
        if (res.data.code == 1) {
          this.paper = res.data.data;
          this.test_paper = res.data.data.cache_file;
          let a = res.data.data.user_answer
          let b = []
          for (const key in a) {
            b[key.replace(/index_/g,'')] = a[key].answer
          }
          this.$store.commit("PaperState", b);
          // console.log(res.data.data);
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        }
        // console.log("试卷11111", res);
        // this.$axios({
        //   method: "get",
        //   url: "https://res-qd.qinxueedu.cn/resources/papers/20210719/3_1626663652.json",
        // }).then((response) => {
        //   // console.log("11111",response); //请求成功返回的数据
        // });
      });
    },
    // 获取二维码
    getCode() {
      api.download_qrcode().then((res) => {
        // // console.log("二维码", res);
        if (parseInt(res.data.code) == 1) {
          this.code = res.data.data.qrcode;
        }
      });
    },
    // 开始考试  去试卷页面
    startTest() {
      this.$router.push({
        name: "Answer",
        // query: { url: this.test_paper }
        params: {
          is_pageid: 9,
          chapter_id: this.$route.params.chapter_id,
          paper_id: this.paper.id,
          paper_url: this.test_paper,
          is_finish: 1,
          router_name: this.$route.params.router_name,
        },
      });
      let storeArr = (this.$store.state.user_answer = []);
      // eventBus.$emit("url",this.test_paper)
    },
    // 继续考试  去试卷页面
    continueTest() {
      this.$router.push({
        name: "Answer",
        params: {
          is_pageid: 9,
          chapter_id: this.$route.params.chapter_id,
          paper_id: this.paper.id,
          paper_url: this.test_paper,
          is_finish: 0,
          router_name: this.$route.params.router_name,
        },
      });
      localStorage.setItem("continueInfo", JSON.stringify(this.paper));
      let storeArr = (this.$store.state.user_answer = []);
      // eventBus.$emit("url",this.test_paper)
    },
    // 科目列表
    getSubject() {
      api.subject().then((res) => {
        this.subjectList = res.data.data;
      });
    },
    // 提交手机号
    onSubmit() {
      let mobile = this.formInline.mobile; //用户手机号
      let ts = Date.parse(new Date()); //当前时间戳
      let cid = 2; //渠道号
      let subject_id = this.formInline.subject_id; //科目id
      let seed = cid + "-" + mobile + "-" + subject_id + "-" + ts;
      let sign = this.$md5(
        this.$md5(seed).toString().toLowerCase() + "landing_page"
      )
        .toString()
        .toLowerCase(); // 签名计算结果
      const reg = /^1[3-9][0-9]{9}$/; // 正则校验手机号
      if (!this.formInline.subject_id) {
        this.$message.error({
          duration: 1500,
          message: "请选择专业!",
        });
        return false;
      } else if (
        this.formInline.mobile == "" ||
        this.formInline.mobile <= 10 ||
        !reg.test(this.formInline.mobile)
      ) {
        this.$message.error({
          duration: 1500,
          message: "请输入正确手机号!",
        });
        return false;
      } else if (this.$cookies.get("mobile") == mobile) {
        this.$message.error({
          duration: 2500,
          message: "一个手机号只允许提交一次,您已提交过,请勿重复提交!",
        });
        return false;
      } else {
        var data = {
          mobile: mobile,
          ts: ts,
          cid: cid,
          subject_id: subject_id,
          sign: sign,
        };
        api.landing_site(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            this.$message.success({
              duration: 1500,
              // message: "提交成功,一个手机号只允许提交一次,请勿重复提交!",
              message: "提交成功!",
            });
            this.formInline = {};
            this.$cookies.set("mobile", mobile, 30);
            let routerName = this.$route.params.router_name;
            if (routerName == "历年真题") {
              this.$router.push({
                name: "TrueTopic",
                params: {
                  is_pageid: 9,
                  select_id: 3,
                },
              });
            } else if (routerName == "模拟试题") {
              this.$router.push({
                name: "Simulation",
                params: {
                  is_pageid: 9,
                  select_id: 4,
                },
              });
            } else if (routerName == "做题记录") {
              this.$router.push({
                name: "Record",
                params: {
                  is_pageid: 9,
                  select_id: 5,
                },
              });
            }
          } else {
            this.$message.error({
              duration: 1500,
              message: "提交失败,请重新提交!",
            });
          }
        });
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#app {
  // background: #f5f5f5;
  // 内容区
  .content_box {
    width: 100%;
    // height: 100vh;
    background: #f5f5f5;
    // margin-bottom: 224px;
    padding-bottom: 224px;
    .content {
      width: 1200px;
      margin: auto;
      overflow: hidden;
      // 标题
      .title_box {
        margin: 28px 0 24px;
        display: flex;
        span {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #484848;
        }
        span:nth-child(1) {
          cursor: pointer;
        }
      }
      // 介绍
      .introduce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // 二维码
        .code_box {
          width: 310px;
          height: 354px;
          background: #fff;
          display: flex;
          flex-flow: column;
          align-items: center;
          span {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin: 21px 0 8px;
          }
          div:nth-child(2) {
            display: flex;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            p {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #3e7eff;
            }
          }
          .line {
            width: 260px;
            height: 1px;
            // border: 1px solid #f5f5f5;
            background: #f5f5f5;
            border-radius: 1px;
            margin: 20px 0 37px;
          }
          .code_img {
            width: 189px;
            height: 188px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../../../assets/img/kuang.png") no-repeat;
            background-size: 189px 188px;
            img {
              width: 162px;
              height: 162px;
            }
          }
        }
        .introduce_left {
          width: 880px;
          // height: 550px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          overflow: hidden;
          // 试卷名字
          .paper_name {
            width: 100%;
            height: 76px;
            background: #fff;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 76px;
            text-align: center;
          }
          // 试卷介绍
          .paper_info {
            width: 100%;
            // height: 464px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 0;
            margin-top: 10px;
            // 没有权限
            .no_access {
              width: 820px;
              height: 484px;
              background: #fafbfd;
              border-radius: 10px;
              display: flex;
              flex-flow: column;
              align-items: center;
              overflow: hidden;
              // 立即报名页面
              .no_enroll {
                display: flex;
                flex-flow: column;
                align-items: center;
                img {
                  width: 286px;
                  height: 224px;
                  margin-top: 69px;
                }
                div {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #999999;
                  margin: 29px 0 15px;
                }
                button {
                  width: 200px;
                  min-height: 46px;
                  background: #3e7eff;
                  border-radius: 6px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                }
              }
              // 联系客服页面
              .contact_box {
                width: 500px;
                display: flex;
                flex-flow: column;
                // align-items: center;
                p:nth-child(1) {
                  font-size: 20px;
                  font-family: DFPSongW9-GB;
                  font-weight: 580;
                  color: #333333;
                  text-align: center;
                  margin-top: 50px;
                }
                p:nth-child(2) {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  margin: 11px auto 55px;
                }
                .ipt_box {
                  div {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    div {
                      color: #d44847;
                    }
                  }
                  .el-select {
                    margin-top: 12px;
                    /deep/ .el-input__inner {
                      width: 500px;
                      height: 50px;
                      background: #fafbfd;
                    }
                  }
                  /deep/.el-input__inner {
                    width: 500px;
                    height: 50px;
                    background: #fafbfd;
                    // margin-top: 12px;
                  }
                }
                button {
                  width: 200px;
                  height: 50px;
                  background: #3e7eff;
                  border-radius: 6px;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                  margin: 40px auto 0;
                }
              }
            }
            // 试卷正常状态
            .info_content {
              width: 760px;
              height: 304px;
              background: #fafbfd;
              border-radius: 10px;
              padding: 50px 30px;
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              // 顶部
              .info_top {
                width: 100%;
                // background: skyblue;
                display: flex;
                justify-content: center;
                ul {
                  // width: 700px;
                  min-width: 660px;
                  // min-width: 100px;
                  // max-width: 100%;
                  max-width: 100%;
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  // margin-top: 50px;
                  // margin: auto;
                  // background: pink;
                  li {
                    // min-width: 164px;
                    width: 31%;
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;
                    img {
                      width: 60px;
                      height: 60px;
                      margin-right: 24px;
                    }
                    div {
                      min-width: 90px;
                      p:nth-child(1) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                      }
                      p:nth-child(2) {
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #222222;
                        white-space: nowrap;
                      }
                    }
                  }
                }
              }
              // 开始做题
              .info_down {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                  display: flex;
                  align-items: center;
                  img {
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                  }
                  p {
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin-left: 6px;
                  }
                }
                button {
                  width: 220px;
                  height: 50px;
                  background: #3e7eff;
                  border-radius: 8px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
